import React, { Component, Fragment } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../../utils/localStorage';
import { Link } from 'react-router-dom'
import { Form, Select, Modal, Tooltip } from 'antd';
import PDFImg from '../../../assets/images/ic-pdf.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DischargeIcon from "../../../assets/images/discharge.png";
import HighAlertIcon from "../../../assets/images/highalert.png";
import InfoIcon from "../../../assets/images/info.png";
import {
  LOADER_RED,
  PATIENT_OP_MEDICINE_LIST,
  PATIENT_OP_TEST_LIST,
  LAB_TEST_CONSTANTS,
  PATIENT_IP_ORDER_LIST,
  PATIENT_IP_MEDICINE_ORDER_LIST,
  PATIENT_IP_OTHER_ORDER_LIST,
  PATIENT_IP_EQUIPMENT_ORDER_LIST,
  DRUGS_CONSTANT_IPD
} from '../../../utils/constant';
import moment from 'moment';
import Moment from 'react-moment';
import MedicineTable from "../../../components/drugs/medicine-table";
import TestTable from "../../../components/tests/test-table";
import qs from 'qs';

const { Option } = Select;

export default class PatientOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEncounterData: props.selectedEncounterData,
      loader: true,
      allOpMedcineList : [],
      allOpTestList : [],
      drugsConstants: null,
      testConstants: null,
      ipd_drug_constants: null,
      allIpTestList : [],
      allIpMedicineList : [],
      allIpEquipmentOrderList: [],
      allIpOtheOrderList: [],
      isTestModalInteractionVisible: false,
      commentValue: null,

    }
  }

  async componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    if(this.state.selectedEncounterData.encounterTypeCode == "OP")
    {
      await this.loadData();
      await this.getPatientOpTestData();
      await this.getPatientOpMedData();
    }
    else
    {
      this.loadIpdMedData();
      await this.getPatientIPTestData();
      await this.getPatientIPMedicineData();
      await this.getPatientIPEquipmentOrderData();
      await this.getPatientIPOtherOrderData();
    }
    
  }

  loadData = async () => {
    let { testConstants } = this.state;

    // Test Constants
    const test_contants = await Axios.get(LAB_TEST_CONSTANTS);
    if (test_contants.data.hasOwnProperty("data") === true) {
      test_contants["radiologyViews"] = test_contants.data?.constants?.radiologyViews;
      test_contants["drugUrgency"] = test_contants.data?.constants?.testUrgency;
    }
    await this.setState({
      testConstants: test_contants
    });
  };

  loadIpdMedData = async () => {
    var ipd_drug_constants;
    const drugsConstantsPromise = Axios.get(DRUGS_CONSTANT_IPD);
    Axios.all([drugsConstantsPromise])
      .then(Axios.spread((...responses) => {
        const response = responses[0];
        if (response.data.hasOwnProperty("data") === true) {
          ipd_drug_constants = response.data.data;
        }
        this.setState({
          ipd_drug_constants
        })
      }))
  }

  async getPatientOpMedData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patientId: selectedEncounterData.registrationId,
      encounter_Id: selectedEncounterData.encounter_id,
      enterprise_id: selectedEncounterData.enterpriseId,
      facility_id: selectedEncounterData.facilityId,
    }
    await Axios.post(PATIENT_OP_MEDICINE_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let medicine_list =  res.status ? res?.data?.data?.details : [];
      let constants =  res.status ? res?.data?.data?.constants : null;
      await this.setState({
        loader: false,
        allOpMedcineList : medicine_list,
        drugsConstants: constants
      });
    })
  }

  showTestModalInteraction = (val) => {
    let showInteractionModal = true;
    this.setState({ commentValue: val, isTestModalInteractionVisible: showInteractionModal });
  };

  handleTestInteractionCancel = () => { this.setState({ isTestModalInteractionVisible: false, commentValue: null }) };

  async getPatientOpTestData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patientId: selectedEncounterData.registrationId,
      encounter_Id: selectedEncounterData.encounter_id,
      enterprise_id: selectedEncounterData.enterpriseId,
      facility_id: selectedEncounterData.facilityId,
    }
    await Axios.post(PATIENT_OP_TEST_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let test_list =  res.status ? res?.data?.data?.details : []
      await this.setState({
        loader: false,
        allOpTestList : test_list
      });
    })
  }

  async getPatientIPTestData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patientId: selectedEncounterData.registrationId,
      encounterId: selectedEncounterData.encounter_id,
      facilityId: selectedEncounterData.facilityId,
      timezoneOffsetMinutes: 330,
      status: 0,
      service_type: 'both',
    }
    await Axios.post(PATIENT_IP_ORDER_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let test_list =  res.status ? res?.data?.data : []
      await this.setState({
        loader: false,
        allIpTestList : test_list
      });
    })
  }

  async getPatientIPMedicineData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patientId: selectedEncounterData.registrationId,
      encounterId: selectedEncounterData.encounter_id,
      facilityId: selectedEncounterData.facilityId,
      timezoneOffsetMinutes: 330,
      status: 0,
      service_type: 'drugs',
    }
    await Axios.post(PATIENT_IP_MEDICINE_ORDER_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let test_list =  res.status ? res?.data?.data : []
      await this.setState({
        loader: false,
        allIpMedicineList : test_list
      });
    })
  }

  async getPatientIPOtherOrderData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      encounterId: selectedEncounterData.encounter_id,
      active: 0
    }
    await Axios.post(PATIENT_IP_OTHER_ORDER_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let other_order_list =  res.status ? res?.data?.data : []
      await this.setState({
        loader: false,
        allIpOtheOrderList : other_order_list
      });
    })
  }

  async getPatientIPEquipmentOrderData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      encounterId: selectedEncounterData.encounter_id,
      enterpriseId: selectedEncounterData.enterpriseId,
      facilityId: selectedEncounterData.facilityId,
      registrationId: selectedEncounterData.registrationId,
    }
    await Axios.post(PATIENT_IP_EQUIPMENT_ORDER_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let other_order_list =  res.status ? res?.data?.data : []
      await this.setState({
        loader: false,
        allIpEquipmentOrderList : other_order_list
      });
    })
  }

  PatientIpOtherOrderLoad() {
    try {
      if ((!this.state.allIpOtheOrderList || this.state.allIpOtheOrderList.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={7}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      const { allIpOtheOrderList } = this.state;
      return allIpOtheOrderList.map((result, i) => {
        let htmlData = (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{result.orderNo}</td>
            <td>{result.orderingPractitionerName}</td>
            <td>{moment(result.enteredDate).format('DD MMM YYYY | hh:mm A ')}</td>
            <td>{result.nursingUnitName}</td>
            <td>{result.priorityName}</td>
            <td>{result.description}</td>
          </tr>
        )
        return htmlData;
      });
    } catch (err) {
      console.log('PatientIpOtherOrderLoad: ', err);
    }
  }

  PatientIpEquipmentOrderLoad() {
    try {
      if ((!this.state.allIpEquipmentOrderList || this.state.allIpEquipmentOrderList.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={4}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      const { allIpEquipmentOrderList } = this.state;
      return allIpEquipmentOrderList.map((result, i) => {
        let htmlData = (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{result.serviceName}</td>
            <td>{moment(result.startTime).add(330,'minutes').format('DD MMM YYYY | hh:mm A ')}</td>
            <td>{moment(result.stopTime).add(330,'minutes').format('DD MMM YYYY | hh:mm A ')}</td>
          </tr>
        )
        return htmlData;
      });
    } catch (err) {
      console.log('PatientIpEquipmentOrderLoad: ', err);
    }
  }

  PatientOpMedicineLoad(){
    const { allOpMedcineList } = this.state;
    if ((!this.state.allOpMedcineList || this.state.allOpMedcineList.length === 0)) {
      let htmlData = (
        <tr>
          <td className="inner_table text-center" colSpan={8}>No Records Found.</td>
        </tr>
      )
      return htmlData;
    }
    return (
        <Fragment>
            <MedicineTable
                drugsData={this.state.allOpMedcineList}
                viewType={"list-visit"}
                showAction={false}
                showScrollable={true}
                showSelectCheck={false}
                constants={this.state.drugsConstants}
            />
        </Fragment>
    );
  }

  PatientOpTestLoad(){
    const { allOpTestList } = this.state;
    if ((!this.state.allOpTestList || this.state.allOpTestList.length === 0)) {
      let htmlData = (
        <tr>
          <td className="inner_table text-center" colSpan={5}>No Records Found.</td>
        </tr>
      )
      return htmlData;
    }
    return (
        <Fragment>
            <TestTable
              testData={this.state.allOpTestList}
              viewType={"list-visit"}
              showAction={false}
              showScrollable={true}
              showSelectCheck={false}
              constants={this.state.testConstants}
            />
        </Fragment>
    );
  }

  PatientIpMedicineLoad = () => {
    if (this.state.allIpMedicineList.length === 0) {
      let htmlData = (
        <tr className="view_details">
          <td colSpan="17" className="inner_table text-center"><strong>No Records Found.</strong></td>
        </tr>
      )
      return htmlData;
    }
    var finalDrugSpan = {};
    var finalOrderSpan = {};
    if (this.state.allIpMedicineList && this.state.allIpMedicineList.length > 0) {

      var drugSpan = 1;
      var orderSpan = 1;
      var current_drug = '';
      var current_order;
      var order_drugs = {};
      for (const medinfo of this.state.allIpMedicineList) {
        if (medinfo.orderId == current_order) {
          if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
            orderSpan += medinfo.timeslots.length
          }  else {
            orderSpan += 1
          }
          
          order_drugs[medinfo.orderId].push(medinfo);
          var thisdrugorder = medinfo.orderId + 'S' + medinfo.serviceId;
          if (thisdrugorder == current_drug) {
            if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
              drugSpan += medinfo.timeslots.length
            } else {
              drugSpan += 1
            }
          }
          else {
            if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
              drugSpan = medinfo.timeslots.length;
            } else {
              drugSpan = 1;
            }
          }
        }
        else {
          order_drugs[medinfo.orderId] = [medinfo];
          if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
            orderSpan = medinfo.timeslots.length;
          } else {
            orderSpan = 1;
          }
          
          if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
            drugSpan = medinfo.timeslots.length;
          } else {
            drugSpan = 1;
          }
        }
        current_drug = medinfo.orderId + 'S' + medinfo.serviceId;
        current_order = medinfo.orderId;
        finalDrugSpan[medinfo.orderId + 'S' + medinfo.serviceId] = drugSpan;
        finalOrderSpan[medinfo.orderId] = orderSpan;
      }
    }
    var current_drug_displayed;
    var current_order_displayed;
    var drug_spaned = true;
    var order_spaned = true;

    const { ipd_drug_constants } = this.state;
    const { dailyFrequency } = ipd_drug_constants;

    return this.state.allIpMedicineList.map((data, i) => {
      let ivForm = data.iV_drug_form && data.iV_drug_form != null ? data.iV_drug_form : '';
      var drugId = data.serviceId;
      let frequencyObjKey = typeof dailyFrequency != "undefined" ? dailyFrequency.find(item => item.id == data.daily_frequency) : null;
      var displayDurationForm = "";
      if (data.frequency_id == 2) {
        displayDurationForm = "Weeks"
      } else {
        if (frequencyObjKey != null && frequencyObjKey != "undefined") {
          if ((frequencyObjKey.nature == "Frequency" && frequencyObjKey.interval_per == "Days") || frequencyObjKey.nature == "Once") {
            displayDurationForm = "Days"
          }
          if (frequencyObjKey.nature == "Frequency" && frequencyObjKey.interval_per == "Weeks") {
            displayDurationForm = "Weeks"
          }
          if (frequencyObjKey.nature == "Frequency" && frequencyObjKey.interval_per == "Months") {
            displayDurationForm = "Months"
          }
        }
      }

      if (current_order_displayed == data.orderId) {
        order_spaned = false;
        var thisdrugorder = data.orderId + 'S' + data.serviceId;
        if (current_drug_displayed == thisdrugorder) {
          drug_spaned = false;
        }
        else {
          drug_spaned = true;
        }
      }
      else {
        order_spaned = true;
        drug_spaned = true;
      }
      current_drug_displayed = data.orderId + 'S' + data.serviceId;
      current_order_displayed = data.orderId;
      let htmlData = [];
      if (data.dose_for_schedule_applicable && data.taperdose_sn <= 1 && data?.timeslots?.length > 1){
        let counter = 1;
        data?.timeslots.map((t, j) => {
          if (counter == 1) {
            htmlData.push(
              <tr key={j} style={{ cursor: "pointer" }}>
                {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>
                  {data.orderNo ? data.orderNo : data.orderId} &nbsp;
                  {(data.orderRemarks === '' || data.orderRemarks === null) ? null :
                    <Tooltip placement="top" title={data.orderRemarks}>
                      <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} />
                    </Tooltip>
                  }
                </td> : null}
                {
                  order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{moment(data?.enteredDate).format('DD MMM YYYY | HH:mm A')}</td> : null
                }
                {
                  order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_Location}</td> : null
                }
                {
                  order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_by}</td> : null
                }
                {drug_spaned == true ?
                  <td style={{ verticalAlign: 'middle' }} rowSpan={finalDrugSpan[data.orderId + 'S' + data.serviceId]}>
                    {data.substituteissued == true ?
                      <Tooltip placement="top" title={data.substituteitemname} zIndex={999999}>
                        <span><i className="icon_substitutes" /> </span>
                      </Tooltip> : null
                    }
                    <div className="d-flex align-items-center">
                      <span className="pr-2">{data.service_name}</span>
                      {(data.remarks === '' || data.remarks === null) ? null :
                        <Tooltip placement="top" title={data.remarks}>
                          <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} className='mx-1' />
                        </Tooltip>
                      }
                      {
                        (data.discharge_medicine == false) ? null :
                          <span><img src={DischargeIcon} alt="discharge" className="existing-med-icon mx-1" style={{ width: '18px', height: 'auto' }} /></span>
                      }
                      {
                        (data.high_alert_medicine == false) ? null :
                          <span><img src={HighAlertIcon} alt="highalert" className="existing-med-icon mx-1" style={{width:'18px',height:'auto'}}/></span>
                      }
                      {
                        (data.controlled_substance_narcotic_alert_medicine && data.controlled_substance_narcotic_alert_medicine == true) ? <span className="bold"> NARCOTIC </span> :
                          null
                      }
                    </div>
                  </td>
                  : null}
                <td rowSpan={data?.timeslots?.length} className={data.give_stat_dose_now == true ? "text-danger" : ""}>{data.priority}</td>
                <td rowSpan={data?.timeslots?.length}>{moment(data.startmedicationdatetime).format("DD MMM YYYY | hh:mm A")}</td>
                <td rowSpan={data?.timeslots?.length}>{frequencyObjKey != null && frequencyObjKey.nature != "PRN" ? moment(data.endmedicationdatetime).format("DD MMM YYYY | hh:mm A") : "-"}</td>
      
                <td rowSpan={data?.timeslots?.length}>{(frequencyObjKey != null && frequencyObjKey.nature != "PRN" && !data.give_stat_dose_now) ? data.number_of_days + " " + displayDurationForm : "-"}</td>
                <td rowSpan={data?.timeslots?.length}>{data.route}</td>
                <td rowSpan={data?.timeslots?.length}>{data.frequency_id == 2 && data.days_in_week_text ? data.daily_frequency_name + " (" + data.days_in_week_text + ")" : data.daily_frequency_name}</td>
                {data.show_additive ? 
                  <td>
                    {
                      !data.is_IV_addictive ?
                        data.dosage + ' ' + data.additive_volume_form + ' @' + data.additive_rate + ' ' + data.additive_rate_form
                        : <Tooltip placement="top" title={"With " + data.iV_drug_name + ' ' + data.additive_volume + ' ' + data.additive_volume_form + ' @' + data.additive_rate + ' ' + data.additive_rate_form}><img src={InfoIcon} alt="View" className="info-med-icon" style={{width:'18px',height:'auto'}} />{data.dosage} {data.uom_first_description !== null ? data.uom_first_description : ''}</Tooltip>
                    }
                  </td>
                  : <td>{t.dosage} {data.dosage_form_text !== null ? data.dosage_form_text : ''}</td>}
                <td>
                  <Moment parse="HH:mm" date={t.scheduletime} format="hh:mm A" />
                </td>
                <td>{t.instruction_desc}</td>
                <td rowSpan={data?.timeslots?.length}>{data.status}</td>
                <td rowSpan={data?.timeslots?.length}>{data?.cancel_reason && data?.cancel_reason != null ? data.cancel_reason : ''}</td>
              </tr>
            )
          } else {
            htmlData.push(
              <tr key={j}>
                <td>{(t.dosage != undefined) ? t.dosage + ' ' + (data.dosage_form_text) ?? data.dosage_form_text : '-'}</td>
                <td>
                  <Moment parse="HH:mm" date={t.scheduletime} format="hh:mm A" />
                </td>

                <td>{t.instruction_desc}</td>
              </tr>
            )
          }
          counter++;
        })
      } else {
        htmlData.push(
          <tr key={i} style={{ cursor: "pointer" }}>
            {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>
              {data.orderNo ? data.orderNo : data.orderId} &nbsp;
              {(data.orderRemarks === '' || data.orderRemarks === null) ? null :
                <Tooltip placement="top" title={data.orderRemarks}>
                  <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} />
                </Tooltip>
              }
            </td> : null}
            {
              order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{moment(data?.enteredDate).format('DD MMM YYYY | HH:mm A')}</td> : null
            }
            {
              order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_Location}</td> : null
            }
            {
              order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_by}</td> : null
            }
            {drug_spaned == true ?
              <td style={{ verticalAlign: 'middle' }} rowSpan={finalDrugSpan[data.orderId + 'S' + data.serviceId]}>
                {data.substituteissued == true ?
                  <Tooltip placement="top" title={data.substituteitemname} zIndex={999999}>
                    <span><i className="icon_substitutes" /> </span>
                  </Tooltip> : null
                }
                <div className="d-flex align-items-center">
                  <span className="pr-2">{data.service_name}</span>
                  {(data.remarks === '' || data.remarks === null) ? null :
                    <Tooltip placement="top" title={data.remarks}>
                      <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} className='mx-1' />
                    </Tooltip>
                  }
                  {
                    (data.discharge_medicine == false) ? null :
                      <span><img src={DischargeIcon} alt="discharge" className="existing-med-icon mx-1" style={{ width: '18px', height: 'auto' }} /></span>
                  }
                  {
                    (data.high_alert_medicine == false) ? null :
                      <span><img src={HighAlertIcon} alt="highalert" className="existing-med-icon mx-1" style={{ width: '18px', height: 'auto' }} /></span>
                  }
                  {
                    (data.controlled_substance_narcotic_alert_medicine && data.controlled_substance_narcotic_alert_medicine == true) ? <span className="bold"> NARCOTIC </span> :
                      null
                  }
                </div>
              </td>
              : null}
            <td className={data.give_stat_dose_now == true ? "text-danger" : ""}>{data.priority}</td>
            <td>{moment(data.startmedicationdatetime).format("DD MMM YYYY | hh:mm A")}</td>
            <td>{frequencyObjKey != null && frequencyObjKey.nature != "PRN" ? moment(data.endmedicationdatetime).format("DD MMM YYYY | hh:mm A") : "-"}</td>
  
            <td>{(frequencyObjKey != null && frequencyObjKey.nature != "PRN" && !data.give_stat_dose_now) ? data.number_of_days + " " + displayDurationForm : "-"}</td>
            <td>{data.route}</td>
            <td>{data.frequency_id == 2 && data.days_in_week_text ? data.daily_frequency_name + " (" + data.days_in_week_text + ")" : data.daily_frequency_name}</td>
            {data.show_additive ? 
              <td>
                {
                  !data.is_IV_addictive ?
                    data.dosage + ' ' + data.additive_volume_form + ' @' + data.additive_rate + ' ' + data.additive_rate_form
                    : <Tooltip placement="top" title={"With " + data.iV_drug_name + ' ' + data.additive_volume + ' ' + data.additive_volume_form + ' @' + data.additive_rate + ' ' + data.additive_rate_form}><img src={InfoIcon} alt="View" className="info-med-icon mr-2" style={{ width: '18px', height: 'auto' }} />{data.dosage} {data.uom_first_description !== null ? data.uom_first_description : ''}</Tooltip>
                }
              </td>
              : <td>{data.dosage} {data.dosage_form_text !== null ? data.dosage_form_text : ''}</td>}
            <td>
              {this.state.show_schedule_med_order ? (data?.schedule_name && data?.schedule_name != null && frequencyObjKey != null && frequencyObjKey.nature != "PRN" && frequencyObjKey.nature != "Once" ? data.schedule_name.replace(/-/g, ", ") : '-') : data?.timeslots && data?.timeslots.length ?
                <Tooltip placement="top" title={data?.timeslots.map((t, i) =>
                  <Moment key={i} parse="HH:mm" date={t.scheduletime} format="hh:mm A, " />
                )}>
                  {data?.timeslots.map((t, i) => {
                    return i < 6 ? <Moment key={i} parse="HH:mm" date={t.scheduletime} format="hh:mm A, " /> : ''
                  }
                  )}{data?.timeslots.length > 6 ? '...' : ''}
                </Tooltip>
                : '-'}
            </td>
            <td>{data.orderInstructions}</td>
            <td>{data.status}</td>
          </tr>
        )
      }
      return htmlData;
    });
  }

  PatientIpTestLoad = () => {
    if (this.state.allIpTestList.length === 0) {
      let htmlData = (
        <tr className="view_details">
          <td colSpan={13} className="inner_table text-center"><strong>No Records Found.</strong></td>
        </tr>
      )
      return htmlData;
    }

    var finalDrugSpan = {};
    var finalOrderSpan = {};
    if (this.state.allIpTestList && this.state.allIpTestList.length > 0) {
      var drugSpan = 1;
      var orderSpan = 1;
      var orderRemarks = '';
      var current_drug = '';
      var current_order;
      var order_drugs = {};
      for (const medinfo of this.state.allIpTestList) {
        if (medinfo.orderId == current_order) {
          
          orderSpan += 1
          order_drugs[medinfo.orderId].push(medinfo);
          var thisdrugorder = medinfo.orderId + 'S' + medinfo.serviceId;
          if (thisdrugorder == current_drug) {
            drugSpan += 1
          }
          else {
            drugSpan = 1;
          }
        }
        else {
          order_drugs[medinfo.orderId] = [medinfo];
          orderSpan = 1;
          drugSpan = 1;
        }
        current_drug = medinfo.orderId + 'S' + medinfo.serviceId;
        current_order = medinfo.orderId;
        finalDrugSpan[medinfo.orderId + 'S' + medinfo.serviceId] = drugSpan;
        finalOrderSpan[medinfo.orderId] = orderSpan;
      }
    }

    var current_drug_displayed;
    var current_order_displayed;
    var drug_spaned = true;
    var order_spaned = true;
    return this.state.allIpTestList.map((data, i) => {


      if (current_order_displayed == data.orderId) {
        order_spaned = false;
        var thisdrugorder = data.orderId + 'S' + data.serviceId;
        if (current_drug_displayed == thisdrugorder) {
          drug_spaned = false;
        }
        else {
          drug_spaned = true;
        }
      }
      else {
        order_spaned = true;
        drug_spaned = true;
      }
      if (data.orderRemarks != "" && data.orderRemarks != null) {
        orderRemarks = orderRemarks
      }
      current_drug_displayed = data.orderId + 'S' + data.serviceId;
      current_order_displayed = data.orderId;
      return (
        <tr className="align-middle my-2" key={i} style={{ cursor: "pointer" }}>
          {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>
            {data.orderNo ? data.orderNo : data.orderId}&nbsp;
            {(data.remarks === '' || data.remarks === null) ? null :
              <Tooltip placement="top" title={data.remarks}>
                <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} />
              </Tooltip>
            }
          </td> : null}
          {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{moment(data?.enteredDate).format('DD MMM YYYY | HH:mm A')}
          {typeof data.TatOutsourceLab!='undefined' && data?.TatOutsourceLab !=null ? <span className='float-right' style={{color:'black'}}><Tooltip placement="topLeft" arrowPointAtCenter={true} title={data?.TatOutsourceLab}>TAT</Tooltip></span> :  null}
          </td> : null}

          {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_Location}</td> : null}
          {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_by}</td> : null}
          {drug_spaned == true ?
            <td style={{ verticalAlign: 'middle' }} rowSpan={finalDrugSpan[data.orderId + 'S' + data.serviceId]}>
              <span> {data.service_name}{data?.unit ? (' (' + data.unit + ')') : null} </span>
            </td>
            : null}

          <td>{data.orderInstructions ? data.orderInstructions : ''}</td>
          <td>{data.doctorName ? data.doctorName : ''}</td>
          <td>{data.department_name}</td>
          <td>{data.priority}</td>
          <td>{data.requisition_comments != null && data.requisition_comments != '' ? <a className='text-primary' onClick={() => this.showTestModalInteraction(data.requisition_comments)}><u>View Requisition Comments</u></a> : <span>No</span>}</td>
          <td style={{ width: '20%' }}> {data.status}</td>     
        </tr>
      )
    });
  }

  
  render() {
    const { loader, selectedEncounterData } = this.state;
    return (
      <>
        { loader ? 
          <div className='row mt-3'>
            <div className="col-12  text-center mt-4">{LOADER_RED}
            </div>
          </div>
          :
          <div className='row mt-3'>
            <div className='col-12'>
              <ul className="nav nav-pills myhealthcare-tabs auto-width test-results-tabs mb-0 mt-2" id="pills-order-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                      <a className="nav-link active" id="pills-tests-procedure-order-tab" data-toggle="pill" href="#pills-tests-procedure-order" role="tab" aria-controls="pills-tests-procedure-order" aria-selected="true">Tests/Procedure Order</a>
                  </li>
                  <li className="nav-item" role="presentation">
                      <a className="nav-link" id="pills-medication-order-tab" data-toggle="pill" href="#pills-medication-order" role="tab" aria-controls="pills-medication-order" aria-selected="false">Medication Order</a>
                  </li>
                  { selectedEncounterData.encounterTypeCode !== "OP" ?
                    <>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="pills-equipment-order-tab" data-toggle="pill" href="#pills-equipment-order" role="tab" aria-controls="pills-equipment-order" aria-selected="false">Equipment Order</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="pills-other-order-tab" data-toggle="pill" href="#pills-other-order" role="tab" aria-controls="pills-other-order" aria-selected="false">Other Order</a>
                    </li>
                    </>
                    :
                    null
                  }
              </ul>

              <div className="tab-content p-2" style={{ border: 'solid 1px #ccc' }} id="pills-order-tabContent">

                {/* ### tab content for Tests/Procedure Order tab ### */}
                <div className="tab-pane fade show active" id="pills-tests-procedure-order" role="tabpanel" aria-labelledby="pills-tests-procedure-order-tab">
                    { selectedEncounterData.encounterTypeCode == "OP" ?
                      this.PatientOpTestLoad()
                      :
                      <>
                        <div className='row mt-3'>
                          <div className='col-12'>
                          <div className="table-responsive scrollable_sub_table">
                              <table className='table table-bordered '>
                                <thead>
                                    <th style={{width:'120px',minWidth:'120px'}} className="text-left align-middle">ORDER ID</th>
                                    <th style={{width:'120px',minWidth:'120px'}} className="text-left align-middle">DATE & TIME</th>
                                    <th style={{width:'150px',minWidth:'150px'}} className="text-left align-middle">ORDERING LOCATION</th>
                                    <th style={{width:'130px',minWidth:'130px'}} className="text-left align-middle">ORDERED BY</th>
                                    <th style={{width:'170px',minWidth:'170px'}} className="text-left align-middle">ORDER NAME</th>
                                    <th style={{width:'130px',minWidth:'130px'}} className="text-left align-middle">INSTRUCTION</th>
                                    <th style={{width:'130px',minWidth:'130px'}} className="text-left align-middle">PERFORMING PRACTITIONER </th>
                                    <th style={{width:'140px',minWidth:'140px'}} className="text-left align-middle">DEPARTMENT</th>
                                    <th style={{width:'85px',minWidth:'85px'}} className="text-left align-middle">PRIORITY</th>
                                    <th style={{width:'130px',minWidth:'130px'}} className="text-left align-middle">REQUISITION COMMENTS</th>
                                    <th style={{width:'110px',minWidth:'110px'}} className="text-left align-middle">STATUS</th>
                                </thead>
                                <tbody>
                                  {this.PatientIpTestLoad()}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                </div>
                {/* ### end of  Tests/Procedure Order tab ### */}

                {/* ### tab content for Medication Order tab ### */}
                <div className="tab-pane fade" id="pills-medication-order" role="tabpanel" aria-labelledby="pills-medication-order-tab">
                    { selectedEncounterData.encounterTypeCode == "OP" ?
                      this.PatientOpMedicineLoad()
                      :
                      <>
                        <div className='row mt-3'>
                          <div className='col-12'>
                          <div className="table-responsive scrollable_sub_table">
                            <table className='table table-bordered '>
                                <thead>
                                    <th style={{width: '120px', minWidth: '120px' }} className="text-left align-middle">ORDER ID</th>
                                    <th style={{ width: '120px', minWidth:'120px'}}  className="text-left align-middle">DATE & TIME</th>
                                    <th style={{ width: '140px', minWidth:'140px'}}  className="text-left align-middle">ORDERING LOCATION</th>
                                    <th style={{ width: '130px', minWidth:'130px'}}  className="text-left align-middle">ORDERED BY</th>
                                    <th style={{ width: '170px', minWidth:'170px'}}  className="text-left align-middle">ORDER NAME</th>
                                    <th style={{ width:'70px',minWidth:'70'}}  className="text-left align-middle">PRIORITY</th>
                                    <th style={{ width: '120px', minWidth:'120px'}}  className="text-left align-middle">START DATE</th>
                                    <th style={{ width: '120px', minWidth:'120px'}}  className="text-left align-middle">END DATE</th>
                                    <th style={{ width: '80px',   minWidth:'80px'}}  className="text-left align-middle">DURATION</th>
                                    <th style={{ width: '80px', minWidth:'80px'}}  className="text-left align-middle">ROUTE</th>
                                    <th style={{ width: '80px', minWidth:'80px'}}  className="text-left align-middle">FREQUENCY</th>
                                    <th style={{ width: '80px', minWidth:'80px'}}  className="text-left align-middle">VOL/DOSAGE</th>
                                    <th style={{ width: '90px', minWidth:'90px'}}  className="text-left align-middle">SCHEDULE</th>
                                    <th style={{ width: '100px', minWidth:'100px'}}  className="text-left align-middle">INSTRUCTION</th>
                                    <th style={{ width: '90px', minWidth:'90px'}}  className="text-left align-middle">STATUS</th>
                                    <th style={{width:'90px',minWidth:'90px'}} className="text-left align-middle">REASON</th>
                                </thead>
                                <tbody>
                                  {this.PatientIpMedicineLoad()}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                </div>
                {/* ### end of  Medication Order tab ### */}

                { selectedEncounterData.encounterTypeCode !== "OP" ?
                  <>
                  <div className="tab-pane fade" id="pills-equipment-order" role="tabpanel" aria-labelledby="pills-equipment-order-tab">
                      <div className='row mt-3'>
                      <div className='col-12'>
                          <div className="table-responsive scrollable_sub_table">
                            <table className='table table-bordered '>
                            <thead>
                                <th width="10%" className="text-left align-middle">S.NO</th>
                                <th width="20%" className="text-left align-middle">EQUIPMENT</th>
                                <th width="20%" className="text-left align-middle">START TIME</th>
                                <th width="20%" className="text-left align-middle">END TIME</th>
                            </thead>
                            <tbody>
                              {this.PatientIpEquipmentOrderLoad()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="pills-other-order" role="tabpanel" aria-labelledby="pills-other-order-tab">
                    <div className='row mt-3'>
                      <div className='col-12'>
                          <div className="table-responsive scrollable_sub_table">
                            <table className='table table-bordered '>
                              <thead>
                                  <th width="6%" className="text-left align-middle">S.NO</th>
                                  <th width="16%" className="text-left align-middle">ORDER NO</th>
                                  <th width="20%" className="text-left align-middle">DOCTOR NAME</th>
                                  <th width="16%" className="text-left align-middle">DATE/TIME</th>
                                  <th width="18%" className="text-left align-middle">NURSING UNIT</th>
                                  <th width="10%" className="text-left align-middle">PRIORITY</th>
                                  <th width="" className="text-left align-middle">OTHER DESCRIPTION</th>
                              </thead>
                              <tbody>
                                {this.PatientIpOtherOrderLoad()}
                              </tbody>
                            </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  </>
                  :
                  null
                }
              </div>
            </div>
          </div>
        }

        <Modal
            className="myTestDrugsInteraction_modal"
            id="myTestDrugsInteraction"
            title="Requisition Comments"
            visible={this.state.isTestModalInteractionVisible}
            onOk={() => this.handleTestInteractionCancel()}
            onCancel={() => this.handleTestInteractionCancel()}
            footer={false}
          >

          <div className="col-12">
            <textarea readOnly name="noi-comments" id="noi-comments" data-name="noi-comments" value={this.state.commentValue} className="form-control drug-input input-template mandatory-field" placeholder="Comments" rows="4" cols="50"></textarea>
          </div>

          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={() => this.handleTestInteractionCancel()}>CLOSE</button>
          </div>
        </Modal>
      </>
    )
  }
}
